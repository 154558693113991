<template>
  <div class="lottery-results">
    <el-card>
      <!-- 搜索栏 -->
      <div class="toolbar">
        <el-select
          v-model="selectedLotteryId"
          placeholder="选择彩种"
          @change="handleLotteryChange"
          style="width: 200px"
          popper-class="lottery-select-dropdown"
        >
          <el-option
            v-for="item in lotteryList"
            :key="item.id"
            :label="item.name"
            :value="item.id"
          />
        </el-select>
        <el-button 
          type="primary" 
          icon="el-icon-plus"
          @click="handleAdd"
          style="margin-left: 10px"
        >添加记录</el-button>
        <el-button 
          type="success" 
          icon="el-icon-refresh" 
          :loading="loading"
          @click="handleRefresh"
          style="margin-left: 10px"
        >刷新</el-button>
      </div>

      <!-- PC端表格 -->
      <el-table
        v-if="!isMobile"
        :data="resultList"
        v-loading="loading"
        style="width: 100%; margin-top: 20px"
        :header-cell-style="{ padding: '8px 0' }"
        :cell-style="{ padding: '4px 0' }"
      >
        <el-table-column prop="id" label="ID" width="60" align="center" />
        <el-table-column label="彩种" width="240">
          <template slot-scope="scope">
            <div class="lottery-info">
              <el-image
                :src="getLotteryIcon(scope.row.lotteryId)"
                style="width: 24px; height: 24px; margin-right: 6px"
              />
              <span class="lottery-name">{{ getLotteryName(scope.row.lotteryId) }}</span>
            </div>
          </template>
        </el-table-column>
        <el-table-column prop="issue" label="期号" width="140" />
        <el-table-column label="开奖号码" min-width="150">
          <template slot-scope="scope">
            <div class="lottery-numbers">
              <span 
                v-for="(num, index) in scope.row.numbers.split(',')" 
                :key="index"
                class="number-ball"
              >
                {{ num.padStart(2, '0') }}
              </span>
            </div>
          </template>
        </el-table-column>
        <el-table-column label="操作" width="120" fixed="right">
          <template slot-scope="scope">
            <el-button 
              type="text" 
              @click="handleEdit(scope.row)"
            >
              修改号码
            </el-button>
            <el-button 
              type="text" 
              class="danger" 
              @click="handleDelete(scope.row)"
            >
              删除
            </el-button>
          </template>
        </el-table-column>
      </el-table>

      <!-- 移动端卡片列表 -->
      <div v-else class="mobile-list">
        <el-card 
          v-for="item in resultList" 
          :key="item.id" 
          class="mobile-item"
          shadow="hover"
        >
          <div class="mobile-item-header">
            <div class="mobile-item-icon">
              <el-image 
                :src="getLotteryIcon(item.lotteryId)"
                style="width: 40px; height: 40px"
                fit="cover"
              />
            </div>
            <div class="mobile-item-info">
              <div class="mobile-item-title">{{ getLotteryName(item.lotteryId) }}</div>
              <div class="mobile-item-desc">期号：{{ item.issue }}</div>
            </div>
          </div>
          <div class="mobile-item-body">
            <div class="lottery-numbers">
              <span 
                v-for="(num, index) in item.numbers.split(',')" 
                :key="index"
                class="number-ball"
              >
                {{ num }}
              </span>
            </div>
          </div>
          <div class="mobile-item-footer">
            <el-button type="primary" size="mini" @click="handleEdit(item)">修改号码</el-button>
            <el-button type="danger" size="mini" @click="handleDelete(item)">删除</el-button>
          </div>
        </el-card>
      </div>

      <!-- 分页 -->
      <div class="pagination-container">
        <el-pagination
          background
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange"
          :current-page="currentPage"
          :page-sizes="[10, 20, 50, 100]"
          :page-size="pageSize"
          layout="total, sizes, prev, pager, next, jumper"
          :total="total"
        />
      </div>
    </el-card>

    <!-- 添加/编辑对话框 -->
    <el-dialog
      :title="editForm.id ? '修改开奖号码' : '添加开奖记录'"
      :visible.sync="dialogVisible"
      :width="isMobile ? '95%' : '500px'"
      :fullscreen="isMobile"
      @close="resetForm"
    >
      <el-form :model="editForm" :rules="rules" ref="editForm" label-width="100px">
        <el-form-item label="期号" prop="issue" v-if="!editForm.id">
          <el-input v-model="editForm.issue" placeholder="请输入期号"></el-input>
        </el-form-item>
        <el-form-item label="期号" v-else>
          <span>{{ editForm.issue }}</span>
        </el-form-item>
        <el-form-item label="开奖号码" prop="numbers">
          <el-input 
            v-model="editForm.numbers"
            placeholder="请输入开奖号码，用英文逗号分隔"
          />
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button @click="dialogVisible = false">取 消</el-button>
        <el-button 
          type="primary" 
          @click="submitForm" 
          :loading="submitting"
        >确 定</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
export default {
  name: 'LotteryResults',
  data() {
    return {
      loading: false,
      selectedLotteryId: null,
      resultList: [],
      lotteryList: [],
      currentPage: 1,
      pageSize: 10,
      total: 0,
      dialogVisible: false,
      editForm: {
        id: null,
        issue: '',
        numbers: '',
        lotteryId: null
      },
      submitting: false,
      rules: {
        issue: [
          { required: true, message: '请输入期号', trigger: 'blur' }
        ],
        numbers: [
          { required: true, message: '请输入开奖号码', trigger: 'blur' },
          { pattern: /^[\d,]+$/, message: '号码格式不正确', trigger: 'blur' }
        ]
      },
      isMobile: false
    }
  },
  created() {
    this.checkDevice()
    window.addEventListener('resize', this.checkDevice)
    this.fetchLotteryList()
  },
  beforeDestroy() {
    window.removeEventListener('resize', this.checkDevice)
  },
  methods: {
    // 检查设备类型
    checkDevice() {
      this.isMobile = window.innerWidth < 768
    },
    
    // 获取彩票列表
    async fetchLotteryList() {
      try {
        const res = await this.$http.get('/api/admin/lottery/list')
        if (res.data.code === 200) {
          this.lotteryList = res.data.data.list
          // 从 localStorage 获取上次选择的彩种
          const savedLotteryId = localStorage.getItem('selectedLotteryResultId')
          if (savedLotteryId && this.lotteryList.some(item => item.id === Number(savedLotteryId))) {
            this.selectedLotteryId = Number(savedLotteryId)
          } else if (this.lotteryList.length > 0) {
            this.selectedLotteryId = this.lotteryList[0].id
          }
          this.fetchData()
        }
      } catch (error) {
        console.error('获取彩票列表失败:', error)
        this.$message.error('获取彩票列表失败')
      }
    },

    // 获取开奖记录
    async fetchData() {
      if (!this.selectedLotteryId) return
      
      this.loading = true
      try {
        const params = {
          page: this.currentPage,
          pageSize: this.pageSize,
          lotteryId: this.selectedLotteryId
        }
        
        const res = await this.$http.get('/api/admin/lottery/results', { params })
        if (res.data.code === 200) {
          this.resultList = res.data.data.list
          this.total = res.data.data.total
        }
      } catch (error) {
        console.error('获取开奖记录失败:', error)
        this.$message.error('获取数据失败')
      } finally {
        this.loading = false
      }
    },

    // 处理彩种切换
    handleLotteryChange() {
      // 存储选择的彩种ID
      localStorage.setItem('selectedLotteryResultId', this.selectedLotteryId)
      this.currentPage = 1
      this.fetchData()
    },

    // 添加刷新方法
    handleRefresh() {
      this.fetchData()
    },

    // 修改号码
    handleEdit(row) {
      this.dialogVisible = true
      this.editForm = {
        id: row.id,
        issue: row.issue,
        numbers: row.numbers
      }
    },

    // 删除记录
    handleDelete(row) {
      this.$confirm('确认删除该开奖记录吗？', '提示', {
        type: 'warning'
      }).then(async () => {
        try {
          const res = await this.$http.delete(`/api/admin/lottery/results/${row.id}`)
          if (res.data.code === 200) {
            this.$message.success('删除成功')
            this.fetchData()
          }
        } catch (error) {
          console.error('删除开奖记录失败:', error)
          this.$message.error('删除失败')
        }
      }).catch(() => {})
    },

    // 添加记录
    handleAdd() {
      if (!this.selectedLotteryId) {
        return this.$message.warning('请先选择彩种')
      }
      this.editForm = {
        id: null,
        issue: '',
        numbers: '',
        lotteryId: this.selectedLotteryId
      }
      this.dialogVisible = true
    },

    // 修改提交表单方法
    async submitForm() {
      try {
        await this.$refs.editForm.validate()
        this.submitting = true

        let res
        if (this.editForm.id) {
          // 修改
          res = await this.$http.put(`/api/admin/lottery/results/${this.editForm.id}`, {
            numbers: this.editForm.numbers
          })
        } else {
          // 添加
          res = await this.$http.post('/api/admin/lottery/results', {
            lotteryId: this.editForm.lotteryId,
            issue: this.editForm.issue,
            numbers: this.editForm.numbers
          })
        }

        if (res.data.code === 200) {
          this.$message.success(this.editForm.id ? '修改成功' : '添加成功')
          this.dialogVisible = false
          this.fetchData()
        }
      } catch (error) {
        console.error(this.editForm.id ? '修改开奖号码失败:' : '添加开奖记录失败:', error)
        if (error.response?.data?.message) {
          this.$message.error(error.response.data.message)
        } else {
          this.$message.error(this.editForm.id ? '修改失败' : '添加失败')
        }
      } finally {
        this.submitting = false
      }
    },

    // 修改重置表单方法
    resetForm() {
      this.$refs.editForm?.resetFields()
      this.editForm = {
        id: null,
        issue: '',
        numbers: '',
        lotteryId: null
      }
    },

    // 分页大小改变
    handleSizeChange(val) {
      this.pageSize = val
      this.fetchData()
    },

    // 页码改变
    handleCurrentChange(val) {
      this.currentPage = val
      this.fetchData()
    },

    // 获取彩票图标
    getLotteryIcon(id) {
      const lottery = this.lotteryList.find(item => item.id === id)
      return lottery?.icon || ''
    },

    // 获取彩票名称
    getLotteryName(id) {
      const lottery = this.lotteryList.find(item => item.id === id)
      return lottery?.name || '未知彩种'
    }
  }
}
</script>

<style lang="scss" scoped>
.lottery-results {
  padding: 20px;

  .toolbar {
    display: flex;
    align-items: center;
  }

  .lottery-info {
    display: flex;
    align-items: center;
    font-size: 13px;
    
  }

  .lottery-numbers {
    display: flex;
    gap: 4px;
    flex-wrap: wrap;

    .number-ball {
      display: inline-block;
      width: 24px;
      height: 24px;
      line-height: 24px;
      text-align: center;
      background-color: #f56c6c;
      color: #fff;
      border-radius: 50%;
      font-size: 12px;
    }
  }

  .mobile-list {
    margin-top: 20px;

    .mobile-item {
      margin-bottom: 12px;

      &:last-child {
        margin-bottom: 0;
      }

      .mobile-item-header {
        display: flex;
        margin-bottom: 12px;

        .mobile-item-icon {
          margin-right: 12px;
        }

        .mobile-item-info {
          flex: 1;

          .mobile-item-title {
            font-size: 16px;
            font-weight: 500;
            color: #303133;
            margin-bottom: 4px;
          }

          .mobile-item-desc {
            font-size: 14px;
            color: #909399;
          }
        }
      }

      .mobile-item-body {
        margin-bottom: 12px;
        
        .lottery-numbers {
          justify-content: center;
        }
      }

      .mobile-item-footer {
        display: flex;
        justify-content: flex-end;
        gap: 8px;

        .el-button {
          margin-left: 0;
        }
      }
    }
  }

  .pagination-container {
    margin-top: 20px;
    display: flex;
    justify-content: flex-end;
  }

  .danger {
    color: #F56C6C;
  }
}

// 响应式适配
@media screen and (max-width: 768px) {
  .lottery-results {
    padding: 10px;

    .toolbar {
      margin-bottom: 10px;
      
      .el-select {
        width: 100% !important;
      }

      .el-button {
        padding: 8px;
        margin-left: 8px;
      }
    }

    .pagination-container {
      .el-pagination {
        width: 100%;
        justify-content: center;
      }
    }
  }
}

// 暗黑模式适配
@media (prefers-color-scheme: dark) {
  .mobile-item {
    .mobile-item-header {
      .mobile-item-info {
        .mobile-item-title {
          color: #fff !important;
        }
        
        .mobile-item-desc {
          color: #909399 !important;
        }
      }
    }
  }

  .lottery-numbers {
    .number-ball {
      background-color: #f56c6c !important;
    }
  }
}
</style>

<style lang="scss">
// 注意：这里不使用 scoped，因为需要修改 el-select 的弹出层样式
.lottery-select-dropdown {
  @media screen and (max-width: 768px) {
    width: 90vw !important;
    margin-left: 5vw;
    max-width: none !important;
    
    .el-select-dropdown__item {
      height: 40px;
      line-height: 40px;
      padding: 0 15px;
    }
  }
}
</style> 